import { Navigate } from 'react-router-dom';

import NotFound from 'components/404';
import Home from 'components/home';
import Login from 'components/login';

export interface IRoute {
   path: string;
   element?: JSX.Element;
}

export enum NAVIGATION_PATHS {
   LOGIN = 'login',
   HOME = 'home',
   ORDERS = 'orders',
   PROFILE = 'profile',
   FAQS = 'faqs',
   FAQS_DETAIL = 'faq-detail',
   DASHBOARD = 'dashboard',
   OPERATIONS = 'operations',
   SCHEDULE = 'schedule',
   SCHEDULE_DETAIL = 'scheduleDetail',
   USERS = 'users',
   USERS_LIST = 'users-list',
   SALES = 'sales',
   POST_SALE = 'postSale',
   NOT_FOUND = '404',
   COLLECT_ORDERS = 'collect-orders',
   CLIENTS = 'clients',
   CREATE_CLIENT = 'create-client',
   CLIENT_DETAIL = 'client-detail',
   CONCIERGE = 'concierge',
   CONCIERGE_SCHEDULER = 'scheduler',
   INITIAL_ADMIN = '/users/users-list',
   INITIAL_CLIENT_USER = '/sales/orders',
   INITIAL_CONCIERGE = '/concierge/scheduler',
}

export const ROUTES: IRoute[] = [
   { path: `/${NAVIGATION_PATHS.LOGIN}`, element: <Login /> },
   { path: `/${NAVIGATION_PATHS.HOME}`, element: <Home /> },
   { path: `/${NAVIGATION_PATHS.NOT_FOUND}`, element: <NotFound /> },

   { path: `${NAVIGATION_PATHS.PROFILE}` },

   { path: `${NAVIGATION_PATHS.USERS}/${NAVIGATION_PATHS.USERS_LIST}` },
   { path: `${NAVIGATION_PATHS.USERS}/${NAVIGATION_PATHS.CLIENTS}` },
   { path: `${NAVIGATION_PATHS.USERS}/${NAVIGATION_PATHS.CREATE_CLIENT}/:clientId` },
   { path: `${NAVIGATION_PATHS.USERS}/${NAVIGATION_PATHS.CLIENT_DETAIL}/:clientId` },

   { path: `${NAVIGATION_PATHS.CONCIERGE}/${NAVIGATION_PATHS.CONCIERGE_SCHEDULER}` },

   { path: `${NAVIGATION_PATHS.SALES}/${NAVIGATION_PATHS.ORDERS}` },
   { path: `${NAVIGATION_PATHS.SALES}/${NAVIGATION_PATHS.COLLECT_ORDERS}` },
   { path: `${NAVIGATION_PATHS.SALES}/${NAVIGATION_PATHS.SCHEDULE}` },

   { path: '*', element: <Navigate to={`/${NAVIGATION_PATHS.NOT_FOUND}`} replace /> },
   { path: '/', element: <Navigate to={`/${NAVIGATION_PATHS.LOGIN}`} replace /> },
];

import { IUser } from 'components/users/user.types';

export interface IFindSchedulesResponse {
   findSchedulesForScheduler: Schedule[];
}

export interface Schedule {
   _id: string;
   createdBy: IUser;
   participants: [IUser];
   status: SCHEDULE_STATUS;
   startDate: Date;
   endDate: Date;
   createdAt: Date;
   updatedAt: Date;
}

export enum SCHEDULE_STATUS {
   CANCELLED = 'CANCELLED',
   IN_APPROVAL = 'IN_APPROVAL',
   APPROVED = 'APPROVED',
   FINISHED = 'FINISHED',
   LOOSE = 'LOOSE',
   ASSOCIATED = 'ASSOCIATED',
}

export interface OrderExtension {
   orderId: string;
   startDate: string;
   _id: string;
}

export interface IGetOrdersExtensionForScheduler {
   getOrdersExtensionForScheduler: OrderExtension[];
}

import moment from 'moment';

import { IModel } from 'components/home/types';
import { SCHEDULE_STATUS } from 'components/schedule/schedule.types';
import ENV from 'config/environment';
import { PortalPage } from 'utils/interfaces';

export const STRUCTURE: PortalPage[] = [
   {
      id: 'Pag_Profile',
      name: 'Profile',
      path: '/profile',
      layout: 'layout_100',
      roles: ['ADMIN', 'CLIENT_USER', 'CONCIERGE'],
      title: 'GENERAL.PROFILE',
      components: [
         {
            position: 0,
            location: 'components/profile',
         },
      ],
   },
   {
      id: 'Pag_Orders',
      name: 'Orders',
      path: '/orders',
      layout: 'layout_100',
      roles: ['ADMIN', 'CLIENT_USER'],
      title: 'COMPONENT_ORDERS.REQUEST_ORDERS',
      components: [
         {
            position: 0,
            location: 'components/orders/orders-filters',
         },
         {
            position: 1,
            location: 'components/generic-list',
            disableTitle: true,
            config: {
               _id: 'orders_list',
               name: 'Orders list',
               url: `${ENV.REACT_APP_PROXY_ENDPOINT}`,
               internalUrl: false,
               proxyIdentifier: 'getOrders',
               listKeys: {
                  list: 'Docs',
                  listInfo: {
                     step: 'PageNumber',
                     limit: 'NumberRows',
                     total: 'NumberTotalRecords',
                  },
               },
               pubSub: {
                  filters: {
                     name: 'OrdersFilters',
                     variables: [
                        {
                           key: 'Estado',
                           newKey: 'status',
                        },
                        {
                           key: 'iCodigoCliente',
                           newKey: 'clientId',
                           default: true,
                        },
                        {
                           key: 'DataIni',
                           newKey: 'startDate',
                           default: true,
                        },
                        {
                           key: 'DataFim',
                           newKey: 'endDate',
                           default: true,
                        },
                     ],
                  },
                  sidesheetId: 'OrderId',
                  getFilters: 'GetFilters',
               },
               dataToTransform: [
                  {
                     label: 'ID do documento',
                     key: 'Documento',
                     newKey: 'id',
                     type: 'plain',
                  },
                  {
                     label: 'COMPONENT_ORDERS.CLIENT_ID',
                     key: 'IdCliente',
                     newKey: 'clientId',
                     type: 'plain',
                     showAsColumn: true,
                  },
                  {
                     label: 'COMPONENT_ORDERS.PO',
                     key: 'PO',
                     newKey: 'po',
                     type: 'plain',
                     showAsColumn: true,
                  },
                  {
                     label: 'COMPONENT_ORDERS.DATE_OF_ISSUE',
                     key: 'DataEmiss',
                     newKey: 'dateOfIssue',
                     type: 'date',
                     showAsColumn: true,
                  },
                  {
                     label: 'COMPONENT_ORDERS.STATUS',
                     key: 'Estado',
                     newKey: 'status',
                     type: 'tag',
                     showAsColumn: true,
                     color: {
                        default: '#F6F6F9',
                        inProduction: '#FDF4DC',
                        initial: '#F6F6F9',
                        readyToSchedule: '#effddc',
                        scheduled: '#EAFBE7',
                        delivered: '#FCECEA',
                     },
                     translate: {
                        inProduction: 'COMPONENT_ORDERS.IN_PRODUCTION',
                        initial: 'COMPONENT_ORDERS.INITIAL',
                        readyToSchedule: 'COMPONENT_ORDERS.READY_TO_SCHEDULE',
                        scheduled: 'COMPONENT_ORDERS.SCHEDULED',
                        delivered: 'COMPONENT_ORDERS.DELIVERED',
                     },
                  },
                  {
                     label: 'COMPONENT_ORDERS.REQUESTED_DATE',
                     key: 'DataPedida',
                     newKey: 'requestedDate',
                     type: 'date',
                     showAsColumn: true,
                  },
                  {
                     label: 'COMPONENT_ORDERS.SCHEDULED_ORDERS',
                     key: '',
                     newKey: 'ordersPercentage',
                     type: 'progressBar',
                     customKeys: {
                        firstKey: {
                           key: 'TotalLinhas',
                           newKey: 'lineTotal',
                        },
                        secondKey: {
                           key: 'LinhasTrat',
                           newKey: 'treatedLines',
                        },
                     },
                     showAsColumn: true,
                  },
               ],
               detail: {
                  location: 'components/orders/order-detail/order-detail',
                  title: 'COMPONENT_ORDERS.ORDER_DETAIL',
                  props: ['clientId'],
               },
               selectableRows: false,
            },
         },
      ],
   },
   {
      id: 'Pag_CollectOrders',
      name: 'Collect orders',
      path: '/collect-orders',
      layout: 'layout_100',
      roles: ['ADMIN', 'CLIENT_USER'],
      title: 'COMPONENT_COLLECT_ORDERS.LOAD_UNLOAD_REQUESTS_FULL',
      components: [
         {
            position: 0,
            location: 'components/collect-orders/collect-orders-home',
         },
      ],
   },
   {
      id: 'Pag_UsersList',
      name: 'Users list',
      path: '/users-list',
      layout: 'layout_100',
      roles: ['ADMIN'],
      title: 'GENERAL.USERS',
      components: [
         {
            position: 0,
            location: 'components/users',
         },
      ],
   },
   {
      id: 'Pag_Clients',
      name: 'Clients',
      path: '/clients',
      layout: 'layout_100',
      roles: ['ADMIN'],
      title: 'GENERAL.CLIENTS',
      components: [
         {
            position: 0,
            location: 'components/clients',
            routing: {
               CLIENT_DETAIL: 'client-detail',
               CREATE_CLIENT: 'create-client',
            },
         },
      ],
   },
   {
      id: 'Pag_CreateClient',
      name: 'Create client',
      path: '/create-client',
      layout: 'layout_100',
      roles: ['ADMIN'],
      title: 'GENERAL.CLIENT_DETAIL',
      components: [
         {
            position: 0,
            location: 'components/clients/components/client-detail',
            routing: {
               CLIENTS: 'clients',
            },
         },
      ],
   },
   {
      id: 'Pag_ClientDetail',
      name: 'Client',
      path: '/client-detail',
      layout: 'layout_100',
      roles: ['ADMIN'],
      title: 'GENERAL.CLIENT_DETAIL',
      components: [
         {
            position: 0,
            location: 'components/clients/components/client-detail',
            routing: {
               CLIENTS: 'clients',
            },
         },
         {
            position: 1,
            location: 'components/users',
         },
      ],
   },
   {
      id: 'Pag_ConciergeSchedule',
      name: 'Concierge',
      path: '/scheduler',
      layout: 'layout_100',
      roles: ['CONCIERGE'],
      title: 'GENERAL.CONCIERGE_SCHEDULE',
      components: [
         {
            position: 0,
            location: 'components/concierge',
            routing: {
               CLIENTS: 'clients',
            },
         },
      ],
   },
];

export const PORTAL_DUMMY_DATA = {
   orders: [
      {
         id: '08816727-4e84-4702-8bee-136556de1ec5',
         number: 321,
         client: 'Marcos Gonçalves',
         date: '2023-05-18',
         status: 'READY_TO_SCHEDULE',
         total: 123.45,
         quantProds: 2,
         postage: 5,
         agentId: 'CP1234',
         user: 'Staples Portugal',
         paymentType: 'TRANSFER',
         observations: 'Obs',
         address: 'ARMAZEM STAPLES - QT DA MINA, ARNEIROS',
      },
      {
         id: '83629372-12x2-6382-8sdf-753923de1ec2',
         number: 323,
         client: 'Carolina Fernandes',
         date: '2023-05-10',
         status: 'SCHEDULED',
         total: 123.45,
         quantProds: 2,
         postage: 5,
         agentId: 'CP1234',
         user: 'Staples Portugal',
         paymentType: 'TRANSFER',
         observations: 'Obs',
         address: 'ARMAZEM STAPLES - QT DA MINA, ARNEIROS',
      },
      {
         id: '65ec9426-1bf4-4381-8ad6-2bee2bd0160f',
         number: 324,
         client: 'Marcos Gonçalves',
         date: '2023-05-10',
         status: 'IN_PRODUCTION',
         total: 123.45,
         quantProds: 2,
         postage: 5,
         agentId: 'CP1234',
         user: 'Staples Portugal',
         paymentType: 'TRANSFER',
         observations: 'Obs',
         address: 'ARMAZEM STAPLES - QT DA MINA, ARNEIROS',
      },
      {
         id: '65ec9426-1bf4-4381-8ad6-2bee2bd0134f',
         number: 325,
         client: 'Marcos Gonçalves',
         date: '2022-08-30',
         status: 'DELIVERED',
         total: 123.45,
         quantProds: 2,
         postage: 5,
         agentId: 'CP1234',
         user: 'Staples Portugal',
         paymentType: 'TRANSFER',
         observations: 'Obs',
         address: 'ARMAZEM STAPLES - QT DA MINA, ARNEIROS',
      },
      {
         id: '65ec9426-1bf4-4381-8ad6-2bee2bd0122f',
         number: 326,
         client: 'Marcos Gonçalves',
         date: '2023-05-18',
         status: 'SCHEDULED',
         total: 123.45,
         quantProds: 2,
         postage: 5,
         agentId: 'CP1234',
         user: 'Staples Portugal',
         paymentType: 'TRANSFER',
         observations: 'Obs',
         address: 'ARMAZEM STAPLES - QT DA MINA, ARNEIROS',
      },
      {
         id: '65ec9426-1bf4-4381-8ad6-2bee2bd0122d',
         number: 327,
         client: 'Carolina Fernandes',
         date: '2022-08-30',
         status: 'READY_TO_SCHEDULE',
         total: 123.45,
         quantProds: 2,
         postage: 5,
         agentId: 'CP1234',
         user: 'Staples Portugal',
         paymentType: 'TRANSFER',
         observations: 'Obs',
         address: 'ARMAZEM STAPLES - QT DA MINA, ARNEIROS',
      },
      {
         id: '66ec9426-1bf4-4381-8ad6-2bee2bd0122d',
         number: 328,
         client: 'Marcos Gonçalves',
         date: '2022-08-30',
         status: 'DELIVERED',
         total: 123.45,
         quantProds: 2,
         postage: 5,
         agentId: 'CP1234',
         user: 'Staples Portugal',
         paymentType: 'TRANSFER',
         observations: 'Obs',
         address: 'ARMAZEM STAPLES - QT DA MINA, ARNEIROS',
      },
   ],
   ordersHistory: [
      {
         id: '08816727-4e84-4702-8bee-136556de1ec5',
         statusHistory: [
            {
               date: '2022-10-10',
               status: 'DELIVERED',
               id: '08816727-4e84-4702-8bee-136556de1ec2',
            },
         ],
      },
      {
         id: '83629372-12x2-6382-8sdf-753923de1ec2',
         statusHistory: [
            {
               date: '2022-10-10',
               status: 'READY_TO_SCHEDULE',
               id: '08816727-4e84-4702-8bee-136556de1ec2',
            },
            {
               date: '2022-10-11',
               status: 'DELIVERED',
               id: '08816727-4e84-4702-8bee-136556de1ec3',
            },
         ],
      },
      {
         id: '65ec9426-1bf4-4381-8ad6-2bee2bd0160f',
         statusHistory: [
            {
               date: '2022-10-10',
               status: 'READY_TO_SCHEDULE',
               id: '08816727-4e84-4702-8bee-136556de1e2',
            },
            {
               date: '2022-10-11',
               status: 'DELIVERED',
               id: '08816727-4e84-4702-8bee-136556de1e22',
            },
            {
               date: '2022-10-12',
               status: 'DELIVERED',
               id: '08816727-4e84-4702-8bee-136556de1232',
            },
         ],
      },
      {
         id: '65ec9426-1bf4-4381-8ad6-2bee2bd0134f',
         statusHistory: [
            {
               date: '2022-10-10',
               status: 'SCHEDULED',
               id: '08816727-4e84-4702-8bee-136556d2',
            },
            {
               date: '2022-10-11',
               status: 'SCHEDULED',
               id: '08816727-4e84-4702-8bee-556de1e2',
            },
            {
               date: '2022-10-12',
               status: 'READY_TO_SCHEDULE',
               id: '08816727-4e84-4702-1238bee-136556de1e2',
            },
            {
               date: '2022-10-13',
               status: 'READY_TO_SCHEDULE',
               id: '08816727-4e84123-4702-8bee-136556de1e2',
            },
         ],
      },
      {
         id: '65ec9426-1bf4-4381-8ad6-2bee2bd0122f',
         statusHistory: [
            {
               date: '2022-10-10',
               status: 'IN_PRODUCTION',
               id: '08816123727-4e84-4702-8bee-136556de1e2',
            },
            {
               date: '2022-10-11',
               status: 'IN_PRODUCTION',
               id: '08816727-4e84-4702-8be23e-136556de1e2',
            },
            {
               date: '2022-10-12',
               status: 'IN_PRODUCTION',
               id: '08816727-4e84-4702-8b-136556de1e2',
            },
            {
               date: '2022-10-13',
               status: 'IN_PRODUCTION',
               id: '08816727-4e84-4702-8be56de1e2',
            },
         ],
      },
      {
         id: '65ec9426-1bf4-4381-8ad6-2bee2bd0122d',
         statusHistory: [
            {
               date: '2022-10-10',
               status: 'SCHEDULED',
               id: '08816727-4e84-4702-8bee-1365156de1e2',
            },
            {
               date: '2022-10-11',
               status: 'SCHEDULED',
               id: '08816727-4e84-4702-8bee-1365356de1e2',
            },
            {
               date: '2022-10-12',
               status: 'SCHEDULED',
               id: '08816727-4e84-4702-8bee-1365256de1e2',
            },
            {
               date: '2022-10-13',
               status: 'SCHEDULED',
               id: '08816727-4e84-4702-8bee-136556d6e1e2',
            },
         ],
      },
      {
         id: '66ec9426-1bf4-4381-8ad6-2bee2bd0122d',
         statusHistory: [
            {
               date: '2022-10-10',
               status: 'DELIVERED',
               id: '088146727-4e84-4702-8bee-136556de1e2',
            },
            {
               date: '2022-10-11',
               status: 'DELIVERED',
               id: '0881216727-4e84-4702-8bee-136556de1e2',
            },
            {
               date: '2022-10-12',
               status: 'DELIVERED',
               id: '088216727-4e84-4702-8bee-136556de1e2',
            },
            {
               date: '2022-10-13',
               status: 'DELIVERED',
               id: '08816727-754e84-4702-8bee-136556de1e2',
            },
         ],
      },
   ],
   clientsList: [
      {
         id: 'Marcos Gonçalves',
         clientId: '5b3c9e6c-0f8f-425d-98a9-4f760af4eb67',
         name: 'Marcos Gonçalves',
         nif: '123456789',
         phoneNumber: '912345678',
         email: 'marcogonc@lo.com',
         createdAt: '2021-08-30',
         lastUpdatedAt: '2021-10-30',
         agentId: '62ec9426-1bf5-4381-8ad6-2bee2bd0122f',
         contract: 'NEW_CONTRACT',
         agentName: 'Staples Portugal',
         status: 'ACTIVE',
      },
      {
         id: 'Carolina Fernandes',
         clientId: 'faf56997-2021-4329-a7a7-ab9bfda9e9bd',
         name: 'Carolina Fernandes',
         nif: '789097867532',
         phoneNumber: '919876545',
         email: 'carolinafern@ndes.com',
         createdAt: '2022-003-30',
         lastUpdatedAt: '2022-11-30',
         agentId: '65ed9426-1bf5-4381-8ad6-2bee2bd0122d',
         contract: 'NEW_CONTRACT',
         agentName: 'Worten Portugal',
         status: 'ANALYZING',
      },
   ],
   dashboard: [
      {
         id: '63dba793c065e60bea0f474b',
         userId: '63dba793c065e60bea0f474b',
         bestMonth: {
            chartPercentage: 68,
            amount: 239200,
            bestMonthAmount: 351764,
         },
         lastOrders: [
            {
               id: 'a596d026-73bc-45b6-a141-a1e829bdd35e',
               number: '123981623',
               status: 'PROCESSING',
               date: '12 Jan 2023',
               agent: 'Staples Portugal',
               amount: 12320,
            },
            {
               id: 'a2253de4-38a4-4565-b258-1f9ea5830347',
               number: '312123321',
               status: 'SHIPPED',
               date: '14 Jan 2023',
               agent: 'Worten Portugal',
               amount: 34512,
            },
            {
               id: '4746be60-d1ce-495d-b086-2db16a84e932',
               number: '172638129',
               status: 'READY_TO_SHIP',
               date: '19 Jan 2023',
               agent: 'Worten Portugal',
               amount: 18273,
            },
         ],
      },
   ],
   genericList: {
      genericList1: {
         _id: 'products_list',
         name: 'Products list',
         url: 'https://mocki.io/v1/13b4e9cc-9404-43fb-9a40-144daca5cfca',
         listField: 'data',
         columns: [
            {
               label: 'ID',
               key: '_id',
               type: 'plain',
            },
            {
               label: 'Marca',
               key: 'name',
               type: 'plain',
            },
            {
               label: 'Descrição',
               key: 'description',
               type: 'plain',
            },
            {
               label: 'Data de nascimento',
               key: 'dob',
               type: 'date',
            },
            {
               label: 'URL',
               key: 'url',
               type: 'externalLink',
            },
            {
               label: 'Salário',
               key: 'salary',
               type: 'currency',
            },
         ],
      },
      genericList2: {
         _id: 'products_list',
         name: 'Products list',
         url: 'https://dummyjson.com/products',
         listField: 'products',
         step: 'skip',
         limit: 'limit',
         total: 'total',
         columns: [
            {
               label: 'ID',
               key: 'id',
               type: 'plain',
            },
            {
               label: 'Marca',
               key: 'brand',
               type: 'tag',
               color: {
                  default: '#00FFFF',
                  Apple: '#FF0000',
                  Samsung: '#00FF00',
                  Huawei: '#0000FF',
               },
            },
            {
               label: 'Preço',
               key: 'price',
               type: 'currency',
            },
            {
               label: 'Imagem',
               key: 'images[0]',
               type: 'external link',
            },
            {
               label: 'Descriçao',
               key: 'description',
               type: 'plain',
            },
            {
               label: 'Percentagem de desconto',
               key: 'discountPercentage',
            },
         ],
      },
      genericList3: {
         _id: 'users_list',
         name: 'Users list',
         url: 'https://reqres.in/api/users',
         listField: 'data',
         step: 'page',
         limit: 'per_page',
         total: 'total',
         columns: [
            {
               label: 'ID',
               key: 'id',
               type: 'plain',
            },
            {
               label: 'Email',
               key: 'email',
               type: 'plain',
            },
            {
               label: 'First Name',
               key: 'first_name',
               type: 'plain',
            },
            {
               label: 'Last Name',
               key: 'last_name',
               type: 'plain',
            },
         ],
      },
   },
   schedules: [
      {
         _id: '100b36ee-6892-4e0b-a5cc-06f43d9484a5',
         title: 'Website Re-Design Plan',
         startDate: moment().set({ hours: 10, minute: 20 }).toDate(),
         endDate: moment().set({ hours: 13, minute: 10 }).toDate(),
         status: SCHEDULE_STATUS.APPROVED,
      },
      {
         _id: '87b01ae0-35f9-44c9-8ebe-970e21b0dc6e',
         title: 'Book Flights to San Fran for Sales Trip',
         startDate: moment().add(1, 'days').set({ hours: 13, minute: 20 }).toDate(),
         endDate: moment().add(1, 'days').set({ hours: 15, minute: 0 }).toDate(),
         status: SCHEDULE_STATUS.IN_APPROVAL,
      },
      {
         _id: 'f9c5dc92-1081-41b2-b2da-496cb11ebc96',
         title: 'Install New Router in Dev Room',
         startDate: moment().add(2, 'days').set({ hours: 12, minute: 0 }).toDate(),
         endDate: moment().add(2, 'days').set({ hours: 14, minute: 0 }).toDate(),
         status: SCHEDULE_STATUS.FINISHED,
      },
   ],
   collectOrders: [
      {
         id: '08816727-4e84-4702-8bee-136556de1ec5',
         number: 321,
         client: 'Marcos Gonçalves',
         date: '2023-05-18',
         status: 'CONFIRMED',
      },
      {
         id: '83629372-12x2-6382-8sdf-753923de1ec2',
         number: 323,
         client: 'Carolina Fernandes',
         date: '2023-05-10',
         status: 'CONFIRMED',
      },
      {
         id: '65ec9426-1bf4-4381-8ad6-2bee2bd0160f',
         number: 324,
         client: 'Marcos Gonçalves',
         date: '2023-05-10',
         status: 'UNCONFIRMED',
      },
   ],
};

export const IMPORT_CLIENTS_CONFIG = {
   vatKey: 'Nif',
   nameKey: 'Nome',
   emailKey: 'Email',
   erpIDKey: 'IdCliente',
   postalCodeKey: 'postalCode',
   cityKey: 'city',
   addressKey: 'address',
};

export const PORTAL_MODULES: IModel[] = [
   {
      id: 'Module_Users',
      srcImage: 'assets/home/users.png',
      label: 'COMPONENT_HOME.USERS',
      basePath: 'users',
      redirectPath: 'users-list',
      backgroundColor: '#FFD966',
      disabled: false,
      icon: 'Group',
      menuOptions: [
         {
            name: 'SIDE_BAR.USERS',
            url: '/users-list',
            roles: ['ADMIN'],
            icon: 'group',
         },
         {
            name: 'SIDE_BAR.CLIENTS',
            url: '/clients',
            roles: ['ADMIN'],
            icon: 'work',
         },
      ],
      roles: ['ADMIN'],
   },
   {
      id: 'Module_Sales',
      icon: 'MonetizationOn',
      srcImage: 'assets/home/sales.png',
      label: 'COMPONENT_HOME.SALES',
      basePath: 'sales',
      redirectPath: 'orders',
      backgroundColor: '#3A98B9',
      disabled: false,
      menuOptions: [
         {
            name: 'SIDE_BAR.ORDERS',
            url: '/orders',
            roles: ['ADMIN', 'CLIENT_USER'],
            icon: 'content_paste',
         },
         {
            name: 'SIDE_BAR.LOAD_UNLOAD_REQUESTS',
            url: '/collect-orders',
            roles: ['ADMIN', 'CLIENT_USER'],
            icon: 'list_alt',
         },
      ],
      roles: ['ADMIN', 'CLIENT_USER'],
   },
   {
      id: 'Module_Concierge',
      icon: 'BackHand',
      srcImage: 'assets/home/sales.png',
      label: 'COMPONENT_HOME.CONCIERGE',
      basePath: 'concierge',
      redirectPath: 'concierge',
      backgroundColor: '#3A98B9',
      disabled: false,
      menuOptions: [
         {
            name: 'SIDE_BAR.CONCIERGE_SCHEDULES',
            url: '/scheduler',
            roles: ['CONCIERGE'],
            icon: 'event',
         },
      ],
      roles: ['CONCIERGE'],
   },
];

import { OrdersStatus } from 'components/orders/types';

export interface IOrderFilter {
   status?: OrdersStatus;
   date_gte?: string;
   date_lte?: string;
}

export interface ICollectOrder {
   _id: string;
   order: string;
   status: ECollectOrdersStatus;
   requestedDate: string;
   partiesConfirmation: {
      admin: boolean;
      client: boolean;
   };
}

export enum ECollectOrdersStatus {
   CONFIRMED = 'CONFIRMED',
   UNCONFIRMED = 'UNCONFIRMED',
   CANCELLED = 'CANCELLED',
}

export enum COLLECT_ORDER_ACTION {
   CHANGE_SUGGESTED_DATE = 'CHANGE_SUGGESTED_DATE',
   CREATED_COLLECT_ORDER = 'CREATED_COLLECT_ORDER',
   CHANGED_STATUS = 'CHANGED_STATUS',
   CONFIRMED_COLLECT_ORDER = 'CONFIRMED_COLLECT_ORDER',
   REJECTED_COLLECT_ORDER = 'REJECTED_COLLECT_ORDER',
   ACCEPTED_COLLECT_ORDER = 'ACCEPTED_COLLECT_ORDER',
}

export interface ICollectOrderLog {
   action: COLLECT_ORDER_ACTION;
   previousValue?: string;
   newValue?: string;
   logCreatedAt: Date;
   user: PartialUser;
}

export interface PartialUser {
   _id: string;
   firstName: String;
   surname: String;

   phoneNumber: String;
   email: String;
}

export enum COLLECT_ORDER_STATUS {
   CANCELLED = 'CANCELLED',
   CONFIRMED = 'CONFIRMED',
   UNCONFIRMED = 'UNCONFIRMED',
}

export interface ICollectOrdersFilter {
   startDate: string;
   endDate: string;
   status?: COLLECT_ORDER_STATUS;
}

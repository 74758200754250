import { Box, ListItemButton } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { IModel } from 'components/home/types';

interface PropTypes {
   index: number;
   item: string;
   option: IModel;
   closedDrawerItemButton: string;
   parentsOpenList: { [key: string]: boolean };
   renderSubOptionsInDrawer: (option: IModel) => JSX.Element;
   toggleItem: (option: IModel) => void;
}

export const MainOptionOpen: FC<PropTypes> = ({
   index,
   item,
   option,
   closedDrawerItemButton,
   parentsOpenList,
   renderSubOptionsInDrawer,
   toggleItem,
}) => {
   const { t } = useTranslation();

   return (
      <Box key={index} sx={{ width: '100% !important' }}>
         <ListItemButton
            selected={false}
            onClick={() => toggleItem(option)}
            className={clsx(item, closedDrawerItemButton)}
            sx={{ width: '100% !important' }}
         >
            <Box
               display="flex"
               alignItems="center"
               justifyContent="space-between"
               width="100%"
            >
               {t(`MODULES.${option?.id?.toUpperCase()}`)}
               {parentsOpenList[option.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Box>
         </ListItemButton>

         {renderSubOptionsInDrawer(option)}
      </Box>
   );
};

import {
   AsyncThunk,
   createAsyncThunk,
   createSlice,
   PayloadAction,
} from '@reduxjs/toolkit';

import { UserState } from 'utils/interfaces';

import ENV, { PATHS } from '../../config/environment';
import HTTP_CLIENT from '../../services/http-client';

const persistToken = (token: string | null) => {
   if (token) localStorage.setItem(ENV.REACT_APP_TOKEN_KEY, token);
};

const persistedToken: string | null =
   localStorage.getItem(ENV.REACT_APP_TOKEN_KEY) || null;

const initialState = {
   access_token: persistedToken,
   pending: false,
   error: false,
   info: undefined,
} as UserState;

export const login: AsyncThunk<
   UserState,
   {
      email: string;
      password: string;
   },
   // eslint-disable-next-line @typescript-eslint/ban-types
   {}
> = createAsyncThunk(
   'users/login',
   async ({ email, password }: { email: string; password: string }) => {
      try {
         const { data } = await HTTP_CLIENT.post(PATHS.LOGIN, {
            email,
            password,
         });

         persistToken(data.access_token);

         return data;
      } catch (err) {
         throw new Error('AUTH.ERROR_AUTHENTICATING');
      }
   },
);

const userSlice = createSlice({
   name: 'users',
   initialState,
   reducers: {
      addUser(state, action: PayloadAction<any>) {
         if (!state.info) state.info = action.payload;
      },
      logout(state) {
         state.info = undefined;
         state.access_token = null;
         localStorage.clear();
      },
   },
   extraReducers: (builder) => {
      // Add reducers for additional action types here, and handle loading state as needed
      builder.addCase(login.fulfilled, (state, action) => {
         // Add user to the state array
         state.access_token = action.payload.access_token;
         state.pending = false;
         state.error = false;
      });

      builder.addCase(login.pending, (state) => {
         state.access_token = null;
         state.pending = true;
         state.error = false;
      });

      builder.addCase(login.rejected, (state, { error }) => {
         state.access_token = null;
         state.pending = false;
         state.error = error.message as string;
      });
   },
});

export default userSlice.reducer;
export const { logout, addUser } = userSlice.actions;

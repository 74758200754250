import { CSSObject, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const drawerWidth = '15.625rem';
export const collapsedDrawerWidth = '5.063rem';

export const BOX_SHADOW =
   '0px 8px 12px 6px rgba(0, 0, 0, 0.02), 0px 4px 4px rgba(0, 0, 0, 0.04)';

export const themeColors = {
   primary: '#a03515',
   primaryLight: '#a0351575',
   primaryEvenLighter: '#a0351533',
   secondary: '#004259',
   secondaryLight: '#00425930',
   secondaryEvenLighter: '#00111730',
   generalContrastText: '#fff',
   divider: 'rgb(223, 231, 235)',
   background: '#FBFBFB',
   backgroundSecondary: '#fff',
   white: '#fff',
   gray: '#C5C5C5',
   grayLight: '#e9e9eb',
   grayEvenLighter: '#f5f5f5',
   success: {
      background: '#EAFBE7',
      fontColor: '#268115',
   },
   warning: {
      background: '#FDF4DC',
      fontColor: '#866308',
   },
   error: {
      background: '#FCECEA',
      fontColor: '#872013',
   },
   neutral: {
      background: '#F6F6F9',
      fontColor: '#414162',
   },
   black: '#000',
   dark: '#252525',
   lightDark: '#575757',
   transparency: '#ffffff00',
   icons: '#0000008a',
   yellow: '#ffd600',
   link: '#4676F2',
};

const useCommonStyles = makeStyles(() => ({
   displayHeight: {
      height: '100vh',
   },
   height100: {
      height: '100%',
   },
   width100: {
      width: '100%',
   },
}));

export const openedMixin = (theme: Theme): CSSObject => ({
   width: drawerWidth,
   transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
   }),
   overflowX: 'hidden',
});

export const closedMixin = (theme: Theme): CSSObject => ({
   transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
   }),
   overflowX: 'hidden',
   width: collapsedDrawerWidth,
});

export const drawerStyle = makeStyles((theme: Theme) => ({
   drawer: {
      width: drawerWidth,
      paddingTop: '0.563rem',
      paddingBottom: '0.563rem',
      height: 'calc(100% - 5rem)',
   },
}));

export const mobileStyle = makeStyles({
   root: {
      paddingLeft: 'min(6%, 2rem)',
      paddingRight: 'min(6%, 2rem)',
      paddingTop: 'min(6%, 0.188rem)',
      paddingBottom: 'min(6%, 0.188rem)',
   },
});

export const shellStyles = makeStyles((theme: Theme) => ({
   container: {
      padding: 0,
   },
   drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
   },
}));

export const sxNonMobile = {
   display: {
      xs: 'none',
      md: 'flex',
   },
};

export const sxMobile = {
   display: {
      xs: 'flex',
      md: 'none',
   },
};

export const sxMobilePaddingTop = {
   paddingTop: {
      xs: 2,
      md: 0,
   },
};

export default useCommonStyles;

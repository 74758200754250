import React from 'react';
import { themeColors } from '../../style/common';
import logo from '../../assets/graphics/logo.png';

export default function Splash() {
   return (
      <div
         style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw',
            backgroundColor: 'rgb(255, 255, 255)',
            color: themeColors.primary,
         }}
      >
         <img src={logo} alt="logo" style={{ width: '10vw' }} />
      </div>
   );
}

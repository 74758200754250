import React, { useCallback, useState, SetStateAction, Dispatch } from 'react';
import { List, ListItemButton, Box, Collapse } from '@mui/material';
import listItemStyles from './style';
import { ArrayString, PortalComponentMenuOption } from 'utils/interfaces';
import { useNavigate } from 'react-router-dom';
import { ISubOption } from './types';
import { IModel } from 'components/home/types';
import useIitRouting from 'hooks/iit-use-routing';

import { SvgIconComponent } from '@mui/icons-material';
import { MainOptionOpen } from './main-option-open';
import { SubOption } from './sub-option';

interface IListItems {
   currentPath: string;
   drawerOpen: boolean;
   subItemState: ArrayString;
   setSubItemState: Dispatch<SetStateAction<ArrayString>>;
   menuOptions?: IModel[];
   setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
}

const MUIcon: { [key: string]: SvgIconComponent } = require('@mui/icons-material');

export default function ListItems({
   menuOptions,
   currentPath,
   drawerOpen,
   setIsDrawerOpen,
}: IListItems) {
   const navigate = useNavigate();
   const { BASE_PATH } = useIitRouting();

   const { item, closedDrawerItemButton } = listItemStyles();

   const openInitialParent = () => {
      const option = menuOptions?.find(
         (option: IModel) => option?.basePath === BASE_PATH,
      );

      if (!option) return {};

      return {
         [option.id]: true,
      };
   };

   const [parentsOpenList, setParentOpenList] = useState<{ [key: string]: boolean }>(
      openInitialParent() || {},
   );

   const toggleItem = useCallback((option: IModel) => {
      setParentOpenList((prev) => ({
         ...prev,
         [option.id]: !prev[option.id],
      }));
   }, []);

   const handleClickSubItem = useCallback(
      (option: PortalComponentMenuOption, basePath: string) => {
         navigate(`/${basePath}${option?.url}`);
      },
      [navigate],
   );

   const itemIsSelected = useCallback(
      (option: ISubOption) => {
         return option?.url === currentPath;
      },
      [currentPath],
   );

   const renderSubOptionsInDrawer = useCallback(
      (option: IModel) => {
         return (
            <Collapse in={parentsOpenList[option.id]} timeout="auto" unmountOnExit>
               {option?.menuOptions?.map((subOption: ISubOption, index: number) => {
                  return (
                     <SubOption
                        key={index}
                        index={index}
                        subOption={subOption}
                        itemIsSelected={itemIsSelected}
                        handleClickSubItem={handleClickSubItem}
                        option={option}
                        currentPath={currentPath}
                        item={item}
                     />
                  );
               })}
            </Collapse>
         );
      },
      [currentPath, handleClickSubItem, item, itemIsSelected, parentsOpenList],
   );

   const renderMainOptionOnOpenDrawer = useCallback(
      (option: IModel, index: number) => {
         return (
            <MainOptionOpen
               key={index}
               closedDrawerItemButton={closedDrawerItemButton}
               index={index}
               item={item}
               option={option}
               parentsOpenList={parentsOpenList}
               renderSubOptionsInDrawer={renderSubOptionsInDrawer}
               toggleItem={toggleItem}
            />
         );
      },
      [
         closedDrawerItemButton,
         item,
         parentsOpenList,
         renderSubOptionsInDrawer,
         toggleItem,
      ],
   );

   const renderMainOptionOnClosedDrawer = useCallback(
      (option: IModel, index: number) => {
         if (!option?.icon) return null;

         const Icon = MUIcon[option?.icon];

         return (
            <ListItemButton
               key={index}
               selected={false}
               onClick={async () => {
                  setParentOpenList({});
                  toggleItem(option);
                  await setIsDrawerOpen(true);
               }}
               className={item}
            >
               {Icon && (
                  <Icon color={option?.basePath === BASE_PATH ? 'primary' : 'inherit'} />
               )}
            </ListItemButton>
         );
      },
      [BASE_PATH, item, setIsDrawerOpen, toggleItem],
   );

   const renderMainOption = useCallback(
      (option: IModel, index: number) => {
         return (
            <Box key={index}>
               {drawerOpen
                  ? renderMainOptionOnOpenDrawer(option, index)
                  : renderMainOptionOnClosedDrawer(option, index)}
            </Box>
         );
      },
      [drawerOpen, renderMainOptionOnClosedDrawer, renderMainOptionOnOpenDrawer],
   );

   return <List sx={{ padding: 0 }}>{menuOptions?.map(renderMainOption)}</List>;
}

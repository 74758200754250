import * as uuid from 'uuid';

const MeasurableService = {
   genSessionId: (): string => {
      const sessionId = uuid.v4() as string;
      localStorage.setItem('SESSION_ID', sessionId);
      return sessionId;
   },
   getSessionId: (): string => {
      const sessionId = localStorage.getItem('SESSION_ID');

      if (!sessionId) return MeasurableService.genSessionId();

      return sessionId;
   },
   sendMeasurable: async (event: Measurable) => {
      try {
         // await axios.post(
         //    ENV.REACT_APP_MEASURABLES_ENDPOINT,
         //    {
         //       ...event,
         //       sessionId: MeasurableService.getSessionId(),
         //    },
         //    {
         //       headers: {
         //          [ENV.MEASURABLES_CONFIG.NAME]: ENV.MEASURABLES_CONFIG.KEY,
         //       },
         //    },
         // );
      } catch (error) {
         // empty try catch because we dont want to display
         // errors to the user related to the measurables
         console.error(error);
      }
   },
};

export { MeasurableService };

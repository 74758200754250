import { MeasurableService } from 'services/measurables-service';
import { useAppSelector } from 'store';

const useMeasurable = () => {
   const USER = useAppSelector((state) => state?.user?.info);

   const saveEventHandler = (mease: CreateMeasurable) => {
      const deviceInfoString = navigator?.userAgent;
      const agentIdentifier = USER?._id;
      MeasurableService.sendMeasurable({
         event: 'DEFAULT',
         value: 1,
         ...mease,
         deviceInfo: { info: deviceInfoString },
         deviceType: 'OTHER',
         date: new Date(),
         withIdentifiedAgent: !!agentIdentifier,
         agentIdentifier: agentIdentifier,
      });
   };

   return { deviceInfo: {}, saveEventHandler };
};

export { useMeasurable };

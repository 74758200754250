export const HEADER_CART = 'HeaderCart';
export const ORDERS_FILTERS = 'OrdersFilters';
export const COLLECT_ORDERS_FILTERS = 'CollectOrdersFilters';
export const USER_PROFILE = 'UserProfile';
export const ALERT = 'Alert';
export const SHARED_HEADER_FILTER = 'SharedHeaderFilter';
export const NOTIFICATIONS_FILTERS = 'NotificationsFilter';
export const SIDE_FILTERS = 'SideFilters';
export const INVITE_USER = 'InviteUser';
export const CLIENTS_FILTERS = 'ClientsFilters';
export const USERS_FILTERS = 'UsersFilters';
export const ORDER_ID = 'OrderId';
export const SELECTED_ROWS = 'SelectedRows';
export const RESET_SELECTED_ROWS = 'ResetSelectedRows';
export const REFETCH_GENERIC_LIST = 'RefetchGenericList';
export const REFETCH_LIST = 'RefetchGenericList';
export const GET_FILTERS = 'GetFilters';
export const UPDATE_ORDER_STATUS = 'UpdateOrderStatus';
export const REFETCH_CLIENTS = 'RefetchClients';

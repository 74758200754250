import React, { useMemo, useState } from 'react';

import { Snackbar } from '@mui/material';
import Alert, { AlertColor } from '@mui/material/Alert';

import { useIitGetObservableWithTopic } from 'hooks/iit-use-get-observable-with-topic';
import { ALERT } from 'utils/pubsub-topics';
import { Nullable } from 'utils/types';
import { setupTheme } from 'core/general-setup';
import { themeColors } from 'style/common';

interface IAlertContent {
   open: boolean;
   severity?: AlertColor;
   message: string;
}

function AlertModal() {
   const [alertContent, setAlertContent] = useState<IAlertContent>({
      open: false,
      message: '',
   });

   const theme = setupTheme();

   const ALERT_COLOR = useMemo(() => {
      switch (alertContent?.severity) {
         case 'success':
            return {
               background: themeColors.success.background,
               fontColor: themeColors.success.fontColor,
            };
         case 'info':
            return {
               background: themeColors.neutral.background,
               fontColor: themeColors.neutral.fontColor,
            };
         case 'warning':
            return {
               background: themeColors.warning.background,
               fontColor: themeColors.warning.fontColor,
            };
         case 'error':
            return {
               background: themeColors.error.background,
               fontColor: themeColors.error.fontColor,
            };
         default:
            return {
               background: themeColors.neutral.background,
               fontColor: themeColors.neutral.fontColor,
            };
      }
   }, [alertContent?.severity]);

   useIitGetObservableWithTopic(ALERT, {
      onCompleted: (data: Nullable<IAlertContent>) => {
         if (data) setAlertContent(data);
      },
   });

   return (
      <Snackbar
         anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         open={alertContent?.open}
         autoHideDuration={2000}
         onClose={() => setAlertContent({ ...alertContent, open: false })}
      >
         <Alert
            elevation={6}
            variant="filled"
            severity={alertContent?.severity || 'info'}
            onClose={() => setAlertContent({ ...alertContent, open: false })}
            sx={{
               borderRadius: theme.spacing(0.5),
               backgroundColor: ALERT_COLOR?.background,
               color: ALERT_COLOR?.fontColor,
            }}
         >
            {alertContent?.message || ''}
         </Alert>
      </Snackbar>
   );
}

export default AlertModal;

import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Box, Button, Drawer, styled, Typography } from '@mui/material';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

import useCommonStyles, {
   closedMixin,
   drawerStyle,
   drawerWidth,
   openedMixin,
} from 'style/common';
import { ArrayString, PortalComponentMenuOption } from 'utils/interfaces';

import ListItems from './components/list-items/list-items';
import listItemStyles from './components/list-items/style';
import sidebarStyles from './style';
import { useAppSelector } from 'store';

interface ISidebar {
   //todo: change any aqui
   menuOptions: any[];
   drawerOpen: boolean;
   setDrawerOpen: Dispatch<SetStateAction<boolean>>;
}

export default function Sidebar({ menuOptions, drawerOpen, setDrawerOpen }: ISidebar) {
   const { pathname } = useLocation();
   const CURRENT_PATH = `/${pathname.split('/')[2]}`;
   const USER = useAppSelector((state) => state?.user?.info);

   const getOption = useCallback((state: boolean, option?: PortalComponentMenuOption) => {
      if (!option?.subOptions?.length) return {};
      return { [option.name]: state };
   }, []);

   const setListOpens: ArrayString = useCallback(() => {
      if (!menuOptions?.length) return {};
      if (CURRENT_PATH) {
         const option = menuOptions.find(
            (option: PortalComponentMenuOption) => option?.url === CURRENT_PATH,
         );
         return getOption(true, option) as ArrayString;
      }

      const option = menuOptions.find(
         (option: PortalComponentMenuOption) => option?.subOptions,
      );
      return getOption(false, option) as ArrayString;
   }, [CURRENT_PATH, getOption, menuOptions]);

   const [subItemState, setSubItemState] = useState<ArrayString>(setListOpens);

   const { closedDrawerItemButton } = listItemStyles();
   const { collapseButton } = sidebarStyles();
   const utilsClasses = useCommonStyles();
   const drawerClasses = drawerStyle();

   const { t } = useTranslation();

   const MuiDrawer = styled(Drawer, {
      shouldForwardProp: (prop) => prop !== 'open',
   })(({ theme, open }) => ({
      width: drawerWidth,
      ...(open && {
         ...openedMixin(theme),
         '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
         ...closedMixin(theme),
         '& .MuiDrawer-paper': closedMixin(theme),
      }),
   }));

   return (
      <MuiDrawer
         open={drawerOpen}
         variant="permanent"
         PaperProps={{ sx: { marginTop: 8.875, boxShadow: 'none' } }}
      >
         <Box className={clsx(utilsClasses.height100, drawerClasses.drawer)}>
            <Box
               display="flex"
               flexDirection="column"
               justifyContent="space-between"
               className={utilsClasses.height100}
               paddingX={2}
            >
               <ListItems
                  menuOptions={menuOptions.filter((option) =>
                     option?.roles?.some((role: string) => USER?.roles?.includes(role)),
                  )}
                  setSubItemState={setSubItemState}
                  currentPath={CURRENT_PATH}
                  subItemState={subItemState}
                  drawerOpen={drawerOpen}
                  setIsDrawerOpen={setDrawerOpen}
               />

               <Button
                  variant="text"
                  color="inherit"
                  sx={{
                     marginBottom: 2,
                     paddingY: 1.125,
                     paddingX: 2,
                  }}
                  className={clsx(drawerOpen ? collapseButton : closedDrawerItemButton)}
                  onClick={() => setDrawerOpen(!drawerOpen)}
               >
                  {!drawerOpen ? (
                     <KeyboardDoubleArrowRightIcon />
                  ) : (
                     <KeyboardDoubleArrowLeftIcon />
                  )}
                  {drawerOpen && (
                     <Typography variant="body1" sx={{ marginLeft: 1 }}>
                        {t('SIDE_BAR.COLLAPSE_SIDEBAR')}
                     </Typography>
                  )}
               </Button>
            </Box>
         </Box>
      </MuiDrawer>
   );
}

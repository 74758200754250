import React from 'react';

import { Box, Typography } from '@mui/material';

import { themeColors } from 'style/common';
import { IProfile } from 'utils/interfaces';

import NotificationRow from './components/notification/notification';

import HeaderMenu from '../../header-menu';
import { useTranslation } from 'react-i18next';

interface IHeaderNotificationsMenu {
   currentUser?: IProfile;
   anchorElNotifications: HTMLElement | null;
   handleCloseNotificationsMenu: () => void;
}

const TYPOGRAPHY_LINK = { textDecoration: 'underline', cursor: 'pointer' };

export default function HeaderNotificationsMenu({
   currentUser,
   anchorElNotifications,
   handleCloseNotificationsMenu,
}: IHeaderNotificationsMenu) {
   const { t } = useTranslation();

   return (
      <HeaderMenu
         handleCloseMenu={handleCloseNotificationsMenu}
         anchorEl={anchorElNotifications}
         sx={{ mt: 4, maxHeight: '19.688rem' }}
      >
         <Box display="flex" alignItems="center" paddingY={1}>
            <Typography variant="h6" flexGrow={1}>
               {t('GENERAL.NOTIFICATIONS')}
            </Typography>
            <Typography variant="body2" sx={TYPOGRAPHY_LINK} color={themeColors.link}>
               {t('GENERAL.MARK_AS_READ')}
            </Typography>
         </Box>

         <NotificationRow currentUser={currentUser} />
         <NotificationRow currentUser={currentUser} />
         <NotificationRow currentUser={currentUser} />
         <NotificationRow currentUser={currentUser} />
         <NotificationRow currentUser={currentUser} />
         <NotificationRow currentUser={currentUser} />
      </HeaderMenu>
   );
}

import React, { FC } from 'react';
import { Box, ListItemButton, ListItemText } from '@mui/material';
import IitListItemIcon from 'components/shared-components/iit-menu-option-icon';
import { LIST_ITEM_TEXT_FONT_SIZE } from 'components/sidebar/style';
import { ISubOption } from './types';
import { IModel } from 'components/home/types';
import { PortalComponentMenuOption } from 'utils/interfaces';
import { useTranslation } from 'react-i18next';

export interface PropTypes {
   index: number;
   subOption: ISubOption;
   currentPath: string;
   itemIsSelected: (option: ISubOption) => boolean;
   option: IModel;
   handleClickSubItem: (option: PortalComponentMenuOption, basePath: string) => void;
   item: string;
}

export const SubOption: FC<PropTypes> = ({
   index,
   subOption,
   currentPath,
   itemIsSelected,
   option,
   handleClickSubItem,
   item,
}) => {
   const { t } = useTranslation();

   return (
      <ListItemButton
         key={index}
         selected={itemIsSelected(subOption)}
         onClick={() => handleClickSubItem(subOption, option?.basePath)}
         className={item}
      >
         <IitListItemIcon option={subOption} currentPath={currentPath} />
         <>
            <Box display="flex" flexDirection="column" flexGrow={1} marginLeft={2}>
               <ListItemText
                  primary={t(subOption?.name)}
                  primaryTypographyProps={{
                     fontSize: LIST_ITEM_TEXT_FONT_SIZE,
                     fontWeight: itemIsSelected(subOption) ? 700 : 400,
                  }}
               />
            </Box>
         </>
      </ListItemButton>
   );
};

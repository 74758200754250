export enum MENU_TYPE {
   USER = 'USER',
   NOTIFICATIONS = 'NOTIFICATIONS',
   MODULES = 'MODULES',
   MODULE_OPTIONS = 'MODULE_OPTIONS',
}

export interface IMenusState {
   type: MENU_TYPE;
   anchorEl: HTMLElement | null;
}

export interface INavbar {
   isWebViewMode: boolean;
   pageTitle?: string;
   menuOptions: any[];
}

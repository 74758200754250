import React from 'react';

import { Badge, Box, Typography } from '@mui/material';

import { IitProfileImage } from 'components/shared-components/iit-profile-image/iit-profile-image';

import notificationStyles from './style';
import { IProfile } from 'utils/interfaces';

interface INotificationRow {
   currentUser?: IProfile;
}

export default function NotificationRow({ currentUser }: INotificationRow) {
   const { badge } = notificationStyles();

   return (
      <Box display="flex" alignItems="center" marginTop={2}>
         <Badge
            color="error"
            badgeContent=" "
            overlap="circular"
            variant="dot"
            className={badge}
         >
            <IitProfileImage name={currentUser?.fullName} color="secondary" />
         </Badge>
         <Box marginLeft={1.5}>
            <Typography variant="body2">23 Dez 2022</Typography>
            <Typography variant="body2">Joao Pereira enviou-lhe uma mensagem</Typography>
         </Box>
      </Box>
   );
}

import React, { useCallback, useMemo, useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import {
   AppBar,
   Box,
   IconButton,
   Theme,
   Toolbar,
   Typography,
   useMediaQuery,
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import { IitProfileImage } from 'components/shared-components/iit-profile-image/iit-profile-image';
import useIitRouting from 'hooks/iit-use-routing';
import { useAppSelector } from 'store';
import { IProfile } from 'utils/interfaces';

import HeaderUserMenu from './components/menus/user-menu/user-menu';
import HeaderNotificationsMenu from './components/menus/notifications-menu/notifications-menu';
import navbarStyles from './style';
import { IMenusState, INavbar, MENU_TYPE } from './types';
import { showComponentMobile } from './utils';

import companyLogo from '../../assets/graphics/logoSide.png';
import { mobileStyle, themeColors } from '../../style/common';

export default function Header({ isWebViewMode, pageTitle, menuOptions }: INavbar) {
   const [menusState, setMenusState] = useState<IMenusState>({
      type: MENU_TYPE.USER,
      anchorEl: null,
   });

   const USER: IProfile = useAppSelector((state) => state?.user?.info as IProfile);
   const { pathname } = useLocation();

   const { companyLogoStyle, cursorPointer } = navbarStyles();
   const mobile = mobileStyle();
   const { t } = useTranslation();
   const { navigateTo } = useIitRouting();

   const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

   const handleOpenMenu = useCallback(
      (menuType: MENU_TYPE, event?: MouseEvent<HTMLElement>) => {
         setMenusState({
            type: menuType,
            anchorEl: event ? event.currentTarget : null,
         });
      },
      [],
   );

   const ANCHOR_EL = useCallback(
      (type: MENU_TYPE) => (menusState.type === type ? menusState.anchorEl : null),
      [menusState],
   );

   const SHOW_ICON_MOBILE = useMemo(
      () => showComponentMobile(pathname, isWebViewMode, isMobile),
      [isMobile, isWebViewMode, pathname],
   );

   return (
      <Box flexGrow={1}>
         <AppBar
            position="fixed"
            color="inherit"
            sx={{ borderBottom: `1px solid ${themeColors.divider}`, boxShadow: 'none' }}
         >
            <Toolbar className={mobile.root}>
               {!isWebViewMode && !isMobile ? (
                  <img className={companyLogoStyle} src={companyLogo} alt="Logo" />
               ) : (
                  <Box display="flex" alignItems="center">
                     {!SHOW_ICON_MOBILE && (
                        <IconButton
                           color="inherit"
                           sx={{ mr: 0.5, pl: 0.5 }}
                           onClick={() => navigateTo(-1)}
                        >
                           <ArrowBackIosNewIcon />
                        </IconButton>
                     )}
                     <Typography variant="h5">{t(pageTitle || '')}</Typography>
                  </Box>
               )}

               <Box flexGrow={1} />

               {!isMobile && !isWebViewMode && (
                  <Box
                     onClick={(event) => handleOpenMenu(MENU_TYPE.USER, event)}
                     className={cursorPointer}
                     marginLeft={3}
                  >
                     <IitProfileImage name={USER?.fullName || ''} />
                  </Box>
               )}

               <HeaderUserMenu
                  handleCloseUserMenu={() => handleOpenMenu(MENU_TYPE.USER)}
                  anchorElUser={ANCHOR_EL(MENU_TYPE.USER)}
                  currentUser={USER}
               />
               <HeaderNotificationsMenu
                  handleCloseNotificationsMenu={() =>
                     handleOpenMenu(MENU_TYPE.NOTIFICATIONS)
                  }
                  anchorElNotifications={ANCHOR_EL(MENU_TYPE.NOTIFICATIONS)}
                  currentUser={USER}
               />
            </Toolbar>
         </AppBar>
      </Box>
   );
}

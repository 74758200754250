import React, {
   ComponentType,
   lazy,
   LazyExoticComponent,
   useCallback,
   useEffect,
   useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import { Box, Grid, Theme, useMediaQuery } from '@mui/material';

import { useAppSelector } from 'store';
import { ArrayString, IPortalComponentConfig, PortalComponent } from 'utils/interfaces';
import { findRole } from 'utils/utils';

import { mobileStyle, themeColors } from '../style/common';
import { NAVIGATION_PATHS } from 'utils/routes';

interface IIitLayout {
   portalComponents: PortalComponent[];
   isWebViewMode: boolean;
   pageTitle?: string;
}

interface IComponents {
   view: LazyExoticComponent<ComponentType<any>>;
   location?: string;
   position?: number;
   routing?: ArrayString;
   path: string;
   showHeader: boolean;
   pageTitle?: string;
   isWebViewMode: boolean;
   width?: number;
   config?: IPortalComponentConfig;
}

const importView = (viewName?: string) => lazy(() => import(`../${viewName}`));

const IitLayout = ({ portalComponents, isWebViewMode, pageTitle }: IIitLayout) => {
   const [components, setComponents] = useState<IComponents[]>([]);
   const { pathname } = useLocation();

   const COMPONENT_PATH = pathname.split('/')[2];

   const user = useAppSelector((state) => state?.user?.info);

   const mobile = mobileStyle();

   const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
   const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

   const transformComponents = useCallback(() => {
      setComponents([]);

      const components = portalComponents.filter((component) => {
         if (!component.roles) return component;

         return findRole(component.roles, user?.roles);
      });

      components.forEach(async (comp: PortalComponent) => {
         const { location, position, routing, width, config, disableTitle, subTitle } =
            comp;

         const view = await importView(location);
         const component: IComponents = {
            location,
            position,
            routing,
            width,
            view,
            path: COMPONENT_PATH,
            showHeader: !isWebViewMode && !isMobile && !disableTitle,
            pageTitle: subTitle || pageTitle,
            isWebViewMode,
            config,
         };
         setComponents((comps: any) => [...comps, component]);
      });
   }, [
      portalComponents,
      user?.roles,
      COMPONENT_PATH,
      isWebViewMode,
      isMobile,
      pageTitle,
   ]);

   useEffect(() => transformComponents(), [pathname, transformComponents]);

   return (
      <Box
         className={mobile.root}
         display="flex"
         flexGrow="1"
         flexDirection={isMobile ? 'column' : 'row'}
         justifyContent={isMobile ? 'normal' : 'space-between'}
         style={{ backgroundColor: themeColors.background }}
      >
         <Grid
            container
            display="flex"
            flexDirection={
               COMPONENT_PATH === NAVIGATION_PATHS.DASHBOARD && !isMobile && !isTablet
                  ? 'row'
                  : 'column'
            }
            flexWrap="wrap"
            columnSpacing={2}
         >
            {components.length > 0 &&
               components
                  .sort((a: any, b: any) => {
                     return a.position - b.position;
                  })
                  .map((component: any, index: number) => {
                     const Component = component.view;
                     return (
                        <Grid
                           item
                           width={
                              component?.width && !isMobile && !isTablet
                                 ? `${component?.width}%`
                                 : '100%'
                           }
                           key={index}
                        >
                           <Component {...component} />
                        </Grid>
                     );
                  })}
         </Grid>
      </Box>
   );
};

export { IitLayout };

import { NAVIGATION_PATHS } from 'utils/routes';

export const homeRedirects = (roles: string[] | undefined) => {
   if (!roles) return NAVIGATION_PATHS.LOGIN;

   if (roles.includes('ADMIN')) return NAVIGATION_PATHS.INITIAL_ADMIN;
   if (roles.includes('CLIENT_USER')) return NAVIGATION_PATHS.INITIAL_CLIENT_USER;
   if (roles.includes('CONCIERGE')) return NAVIGATION_PATHS.INITIAL_CONCIERGE;

   return NAVIGATION_PATHS.LOGIN;
};

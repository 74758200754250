export enum OrdersStatus {
   INITIAL = 'initial',
   IN_PRODUCTION = 'inProduction',
   READY_TO_SCHEDULE = 'readyToSchedule',
   SCHEDULED = 'scheduled',
   DELIVERED = 'delivered',
   ALL = 'ALL',
}

export enum AvailableOrdersStatus {
   INITIAL = 'INITIAL',
   IN_PRODUCTION = 'IN_PRODUCTION',
   READY_TO_SCHEDULE = 'READY_TO_SCHEDULE',
   SCHEDULED = 'SCHEDULED',
   DELIVERED = 'DELIVERED',
   IN_SCHEDULE = 'IN_SCHEDULE',
}

export interface IOrderFilter {
   status?: OrdersStatus;
   startDate?: string;
   endDate?: string;
   clientId?: string;
}

export interface ILineOrderJfa {
   Artigo: string;
   DataPedida: string;
   DescrArt: string;
   Estado: string;
   IdDocLinha: string;
   Linha: number;
   Preco: number;
   Qtd: number;
   Servicos: string;
   Unidade: string;
}

export interface ILineOrder {
   article: string;
   clientId: number;
   description: string;
   id: string;
   line: number;
   price: number;
   quantity: number;
   requestedDate: string;
   services: string;
   status: string;
   unity: string;
}

export interface IOrderLineRequest {
   LinhasDoc: ILineOrderJfa[];
}

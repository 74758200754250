import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const notificationStyles = makeStyles((theme: Theme) => ({
   badge: {
      '& .MuiBadge-badge': {
         width: theme.spacing(1.5),
         height: theme.spacing(1.5),
         borderRadius: theme.spacing(1),
      },
   },
}));

export default notificationStyles;

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { themeColors } from 'style/common';

const profileImageStyles = makeStyles((theme: Theme) => ({
   profileImage: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: theme.spacing(5),
      border: '0.063rem solid',
   },
   primary: {
      borderColor: themeColors.primary,
      backgroundColor: themeColors.primaryLight,
   },
   secondary: {
      borderColor: themeColors.secondary,
      backgroundColor: themeColors.secondaryLight,
   },
   primaryLightSolid: {
      borderColor: themeColors.primary,
      backgroundColor: '#d4a698',
   },
   secondaryLightSolid: {
      borderColor: themeColors.secondary,
      backgroundColor: '#b0d3df',
   },
   admin: {
      borderColor: '#fcc952',
      backgroundColor: '#ddc17f69',
   },
   small: {
      width: theme.spacing(3.375),
      height: theme.spacing(3.375),
      fontSize: theme.spacing(1.5),
   },
   medium: {
      width: theme.spacing(4.375),
      height: theme.spacing(4.375),
   },
   large: {
      width: theme.spacing(5.375),
      height: theme.spacing(5.375),
      fontSize: theme.spacing(2.5),
   },
}));

export default profileImageStyles;

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const loginStyles = makeStyles((theme: Theme) => ({
   logo: {
      objectFit: 'contain',
      width: theme.spacing(25),
      height: theme.spacing(25),
   },
   paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: theme.spacing(8),
      width: '100%',
   },
   form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
   },
   submit: {
      margin: theme.spacing(3, 0, 2),
      height: theme.spacing(7),
   },
   inputs: {
      marginBottom: theme.spacing(2),
      width: '100%',
   },
}));

export default loginStyles;
